@keyframes dce-rotate{from{transform:rotate(0turn);}to{transform:rotate(1turn);}}
@keyframes dce-scanlight{from{top:0;}to{top:97%;}}
.container{width:100%;height:100%;min-width:100px;min-height:100px;background:#eee;position:relative;}
.dce-bg-loading{animation:1s linear infinite dce-rotate;width:40%;height:40%;position:absolute;margin:auto;left:0;top:0;right:0;bottom:0;fill:#aaa;}
.dce-bg-camera{display:none;width:40%;height:40%;position:absolute;margin:auto;left:0;top:0;right:0;bottom:0;fill:#aaa;}
.dce-video-container{position:absolute;left:0;top:0;width:100%;height:100%;}
.dce-scanarea{position:absolute;left:0;top:0;width:100%;height:100%;pointer-events:none;}
.dce-scanarea .dce-scanlight{display:none;position:absolute;width:100%;height:3%;border-radius:50%;box-shadow:0px 0px 2vw 1px #00e5ff;background:#fff;animation:3s infinite dce-scanlight;user-select:none;}
.sel-container{position: absolute;left: 0;top: 0;}
.sel-container .dce-sel-camera{display:block;}
.sel-container .dce-sel-resolution{display:block;margin-top:5px;}
.sel-container .dlr-sel-minletter{display:block;margin-top:5px;}
.dlr-msg-poweredby{position:absolute;left:50%;bottom:10%;transform:translateX(-50%);pointer-events:none;}
.dlr-msg-poweredby svg{height:max(3vmin,17px);fill:#FFFFFF;}