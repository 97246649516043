html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  touch-action: manipulation;
  font-family: 'Sneak-Regular';
}
.App {
  text-align: center;
}

.topBar {
  height: 60px;
  background-color: #2bb6a2;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emjay-logo {
  background: url('./assets/images/emjay_logo.svg') no-repeat;
  width: 100px;
  height: 40px;
  display: block;
  background-size: contain;
  margin: 13px 0 0;
}
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  font-family: 'Sneak-Regular';
}
.react-html5-camera-photo.react-html5-camera-photo-fullscreen {
  height: calc(100vh - 80px - 114px) !important;
}
.appContainer {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.id-scan-wrapper {
  margin-bottom: 100px;
}


/* ---- Signature Page Styles ---- */
.sig-wrapper {
  position: relative;
  width: 100%;
  height: 400px;
  margin: 0 auto;
}

/*.sig-wrapper span {*/
/*  position: absolute;*/
/*  bottom: calc(px;*/
/*  right: 20px;*/
/*  color: #a9a9a9;*/
/*  font-size: 12px;*/
/*}*/

.sigCanvas {
  /*border-bottom: 2px solid black;*/
  width: 100vw;
  height: 40vh;
}
.bottom-line {
  position: absolute;
  bottom: 20%;
  width: 80%;
  border-bottom: 2px #f3f3f3 solid;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: left;
  color: #555555;
}
.clear {
  position: absolute;
  bottom: calc(20% - 1.2rem);
  color: #3c3c3c;
  font-size: 0.8rem;
  left: 80%;
}
.declaration {
  font-size: 0.8rem;
  text-align: left;
  margin: 20px;
}
@media (orientation: landscape) {
  .sig-wrapper .bottom-line {
    bottom: 20%;
  }
  .sig-wrapper .clear {
    bottom: calc(20% - 1.2rem);
    left: 80%;
  }
}

.problems {
  padding: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
}
.problems > label {
  border-radius: 5px;
  max-width: 100px;
  color: #2bb6a2;
  background: #f3f3f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-family: 'Sneak-Bold';
  margin: 5px auto 20px auto;
}
.problems.disabled {
  color: #aaaaaa;
}

.problems p {
  text-align: center;
  width: 100%;
}

.idScanSelector {
  border-radius: 5px;
  max-width: 250px;
  font-family: 'Sneak-Bold';
  color: #2bb6a2;
  background: #f3f3f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 20px auto;
  padding: 0 20px;
  position: relative;
}
.idScanSelector.disabled {
  color: #aaaaaa;
}

.idScanSelector.capture {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.idScanSelector p {
  text-align: center;
  width: 100%;
}
.center-frame {
  overflow: hidden;
  position: relative;
  flex:1;

}
.webcam-preview {
  width: 100vw;
  position: absolute;
  top: 0;
  height: auto;
  /* left: 0px; */
  max-width: 550px;
  left: 50%;
  transform: translateX(-50%);
}
.webcam-preview-legacy {
  width: 100%;
  position: absolute;
  height: 80vw;
  max-width: 550px;
  left: 50%;
  transform: translateX(-50%);
  max-height: calc(80vw - 50px);
  max-width: 100vw;
  height: auto;
  width: auto;
}
.webcam-preview.selfie {
  width: 100vw;
  position: absolute;
  bottom: 0;
  /* left: 0px; */
  max-width: 550px;
  left: 50%;
  transform: translateX(-50%) scaleX(-1);
}
.passport-overlay {
  width: 100%;
  max-width: 550px;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.webcam-loading-overlay {
  width: 100%;
  position: absolute;
  bottom: 0;
  /*max-width: 550px;*/
  background-color: rgba(0,0,0,0.2);
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  /*font-size: 0.9em;*/
}
.webcam-loading-overlay ul {
  text-align: left;
}
.webcam-loading-overlay > div {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}
.passport-overlay img {
  max-height: 35vh;
  max-width: 95vw;
}
.capture-icon {
  background: url('./assets/images/add_photo.svg') no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
  margin: 0;
  position: absolute;
}
.signature-icon {
  background: url('./assets/images/signature.svg') no-repeat;
  width: 30px;
  height: 30px;
  display: block;
  background-size: contain;
  margin: 0;
  position: absolute;
}
.rotate-image {
  background: url('./assets/images/rotate.svg') no-repeat;
  width: 100px;
  height: 100px;
  display: block;
  background-size: contain;
  margin: 0 auto;
  position: relative;
  margin-top: -60px;
}
.thank-you-page-image {
  background: url('./assets/images/ready_to_roll.svg') no-repeat;
  width: 100px;
  height: 100px;
  display: block;
  background-size: contain;
  margin: 0 auto;
  position: relative;
  margin-top: -60px;
}
.close-icon {
  background: url('./assets/images/close.svg') no-repeat;
  width: 20px;
  height: 20px;
  display: block;
  background-size: contain;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
}
.thank-you-text {
  color: white;
  font-family: 'Sneak-Bold';
  margin: 0;
}
.capture-icon__complete {
  background: url('./assets/images/success.svg') no-repeat;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.progress-tracker {
  clear: both;
  margin: 0px 0 20px 0;
  text-align: left;
  color: #999999;
  font-weight: bold;
  margin-top: 30px;
}

.progress-tracker .in-progress {
  color: black;
}

.progress-tracker__indicator {
  width: 80px;
  float: left;
  position: relative;
  font-size: 11px;
}
.progress-tracker__indicator::before {
  display: block;
  content: '';
  background: #e8e8e8;
  height: 3px;
  width: 80px;
  position: absolute;
  top: 2px;
}

.complete::before {
  background: #a59def;
}
.in-progress::before {
  background: #5c5be5;
}

.progress-tracker__indicator:not(:first-of-type) {
  margin-left: 20px;
}

.complete-delivery {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: white;
  background: #5c5be5;
  font-family: 'Sneak-Bold';
}
.complete-delivery.disabled {
  background: #e9e9e9 !important;
}

.pac-screen {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.pac-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.pac-container > div {
  height: 3.5rem;
  width: 3rem;
  margin: 1rem 0.5rem 0 0.5rem;
  background-color: #f3f3f3;
  color: #4a4a4a;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  display: flex;
}
.pac-keypad {
  display: flex;
  flex-direction: column;
  margin-bottom: 180px;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.pac-keypad > div {
  display: flex;
  flex-direction: row;
  border-left: 1px solid #f6f6f6;
  border-right: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  width: 75%;
}
.pac-keypad > div:first-of-type {
  border-top: 1px solid #f6f6f6;
}

.pac-keypad > div > div {
  display: flex;
  flex: 1;
  font-size: 1.5rem;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: white;
  color: #3c3c3c;
}
.pac-keypad > div > div:not(:last-of-type) {
  border-right: 1px solid #f6f6f6;
}

.pac-keypad > div > div:active:not(.disabled) {
  background-color: #2bb6a2;
  color: white;
}
/* ---- Loader Styles ---- */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.id-selector {
  display: flex;
  flex-direction: row;
  max-width: 450px;
  margin: auto auto;
}
.id-selector > div {
  width: 50%;
  height: 230px;

  border: #2bb6a2 solid 3px;
  margin: 15px;
  border-radius: 10px;
  position: relative;
}
.id-selector div img {
  width: 65%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + -25px));
}
.id-selector > div > div {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50px;
  background-color: #2bb6a2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.webcam-container {
  height: 40vh;
  max-height: 40vh;
  position: relative;
}
.webcam-container.selfie {
  height: 90vw;
  max-height: 90vw;
  position: relative;
}

.other-id-type {
  width: 250px;
  margin: 40px auto;
  color: #999999;
}
.other-id-type > h4 {
  text-align: center;
  border-bottom: 1px solid #999999;
  line-height: 0.1em;
  font-size: 0.8em;
}
.other-id-type>h4>span {
  background:#fff;
  padding:0 10px;
}
.other-id-type > div {
  font-size: 0.8em;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 5px solid #2bb6a2;
  border-radius: 50%;
  animation: lds-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2bb6a2 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ---- Waiting Page Styles ---- */
.waiting-wrapper {
  margin: auto auto;
  /*margin-top: 20vh;*/
}

.thankyou-wrapper {
  /*margin-top: 60px;*/
  height: calc(var(--vh, 1vh) * 100 - 60px);
  background: #2bb6a2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.courier-waiting-text {
  text-transform: capitalize;
}

/* @media (min-width: 1025px) {
  .courier-waiting-text {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
} */

.rotate-message {
  color: #2bb6a2;
  padding: 30px;
}

.PhoneInput {
  max-width: 300px;
  margin: 30px auto 0 auto;
}
.PhoneInputInput {
  height: 30px;
  border-radius: 5px;
  border: solid 1px #9c9c9c;
  font-size: 16px;
  padding-left: 0.75rem;
}

.PhoneInputInput::placeholder {
  color: #aaaaaa;
}

.mobile-input-container {
  display: flex;
  max-width: 350px;
  margin: auto auto;
}
.mobile-input-container > div {
  height: 100%;
  margin: auto;
}
.buttonOverlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}
.international-phone-number {
  font-size: 16px;
}
.international-phone-number .react-phone-number-input__icon {
  border: none;
}
.international-phone-number .react-phone-number-input__input {
  border: 1px solid #d8d8d8;
  padding: 23px 5px;
  border-radius: 5px;
}
.international-phone-number .react-phone-number-input__input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #d8d8d8;
  font-size: 12px;
}
.international-phone-number .react-phone-number-input__input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d8d8d8;
  opacity: 1;
  font-size: 12px;
}
.international-phone-number .react-phone-number-input__input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d8d8d8;
  opacity: 1;
  font-size: 12px;
}
.international-phone-number .react-phone-number-input__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d8d8d8;
  font-size: 12px;
}
.international-phone-number .react-phone-number-input__input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d8d8d8;
  font-size: 12px;
}
.international-phone-number .react-phone-number-input__input::placeholder {
  /* Most modern browsers support this now. */
  color: #d8d8d8;
  font-size: 12px;
}
.international-phone-number .react-phone-number-input__input:focus {
  border-color: #d8d8d8;
}
.international-phone-number .react-phone-number-input__error {
  font-size: 10px;
  margin-left: 0;
}

.errorMessage {
  width: 80%;
}
.errorMessage > p:first-child {
  font-weight: bold;
  color: #2bb6a2;
}
.errorIcon {
  max-width: 200px;
}

.backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.problemModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 80%;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}
.closeButton {
  position: absolute;
  right: -15px;
  top: -15px;
  background-color: white;
  border-radius: 100%;
  font-size: 30px;
  border: #2bb6a2 solid 3px;
  width: 30px;
  height: 30px;
  margin: auto auto;
  line-height: 24px;
}
/* Portrait */
@media screen and (orientation: portrait) {
  /* Portrait styles here */
}
/* Landscape */
@media screen and (orientation: landscape) {
  /* Landscape styles here */
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-color: #ccc;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.custom-recapture {
  position: relative;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  top: calc(80vw - 30px);
  width: 140px;
  text-align: center;
  color: #666;
  font-size: 0.8rem;
}
