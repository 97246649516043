@font-face {
  font-family: 'Sneak-Regular';
  src: url('https://i.heyemjay.com/web/fonts/Sneak-Regular.woff2') format('woff2'),
    url('https://i.heyemjay.com/web/fonts/Sneak-Regular.woff') format('woff'),
    url('https://i.heyemjay.com/web/fonts/Sneak-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Sneak-Bold';
  src: url('https://i.heyemjay.com/web/fonts/Sneak-Bold.woff2') format('woff2'),
    url('https://i.heyemjay.com/web/fonts/Sneak-Bold.woff') format('woff'),
    url('https://i.heyemjay.com/web/fonts/Sneak-Bold.ttf') format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Sneak-Regular', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2b2b2b;
  font-family: 'Sneak-Bold', sans-serif;
}

h2 {
  font-size: 1.75rem;
  line-height: 2.125rem;
  @media (min-width: 1025px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
}

p {
  font-size: 1.0625rem;
  line-height: 1.375rem;
}

@media (min-width: 1025px) {
  h2 {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }
}
